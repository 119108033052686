import React, { useState } from "react";
import styled from "styled-components";

const FAQSection = styled.section`
  padding: 133px 0;
  max-width: 1088px;
  margin: 0 auto;

  padding: 150px 20px;
`;

const FAQTitle = styled.h2`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 7px;
`;

const FAQUnderline = styled.div`
  background-color: #29a8c7;
  width: 75px;
  height: 2px;
  margin: 0 auto 49px;

  @media (max-width: 900px) {
    margin-bottom: 40px;
  }
`;

const FAQContent = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start; /* Alinha o conteúdo ao topo */

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const FAQList = styled.div`
  flex: 1;
  width: 100%;
`;

const FAQItem = styled.div`
  background-color: #ebebeb;
  cursor: pointer;
  overflow: hidden;
  transition: border 0.3s ease-in-out, border-radius 0.3s ease-in-out; /* Adiciona transição suave nas bordas */

  /* Bordas arredondadas e borda superior no primeiro item */
  &:first-child {
    border-radius: 20px 0 0 0; /* Borda arredondada no canto superior esquerdo */
  }

  /* Borda inferior esquerda arredondada no último item */
  &:last-child {
    border-radius: 0 0 0 20px; /* Borda arredondada no canto inferior esquerdo */
  }

  /* Borda cinza entre os itens, exceto o último */
  &:not(:last-child) {
    border-bottom: 1px solid #d3d3d3;
  }
`;

const FAQQuestion = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 33px 52px;
  font-weight: 700;
  background-color: #f0f0f0;

  &::after {
    content: "${(props) => (props.expanded ? "-" : "+")}";
    font-size: 20px;
    color: #000;
    transition: transform 0.3s ease;
  }
`;

const FAQAnswer = styled.div`
  padding: 52px 33px;
  background-color: #f8f4f4;
  display: ${(props) => (props.expanded ? "block" : "none")};
`;

const FAQImage = styled.img`
  aspect-ratio: 1.32;
  object-fit: cover;
  width: 100%;
  max-width: 450px;
  height: 375px;
  border-radius: 0 20px 20px 0; /* Pontas retas em cima e embaixo à esquerda, arredondada à direita */

  @media (max-width: 900px) {
    max-height: 375px;
    height: 100%;
    max-width: 100%;
    align-self: center; /* Alinha a imagem no centro em telas menores */
    border-radius: 20px; /* Bordas arredondadas completas em dispositivos móveis */
  }
`;

const SecuritySubtitle = styled.h3`
  color: #29a8c7;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  max-width: 381px;

  @media (min-width: 901px) {
    margin-left: 52px;
  }
`;

const faqData = [
  {
    question: "Quem a Conecta atende?",
    answer:
      "Atualmente, as soluções oferecidas pela Conecta são destinadas a clientes de instituições financeiras, que necessitem de softwares ágeis e otimização dos processos.",
  },
  {
    question: "Quais serviços são oferecidos?",
    answer:
      "Hoje, nosso principal produto é a plataforma TEV, que chegou para revolucionar o processo de emissão da ATPV-e (Autorização para Transferência de Propriedade do Veículo, exigida para veículos registrados a partir de 04/01/2021.).",
  },
  {
    question: "Como funcionam nossas soluções?",
    answer:
      "Facilitamos assinaturas e envios digitais, que agora podem ser feitos em lote ou de forma individual, oferecendo uma abordagem ágil, segura e simplificada para todos os envolvidos.",
  },
];

const FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <FAQSection id="faq">
      <FAQTitle>FAQ</FAQTitle>
      <FAQUnderline />

      <SecuritySubtitle>
        Quais as vantagens de contar com a gente:
      </SecuritySubtitle>

      <FAQContent>
        <FAQList>
          {faqData.map((item, index) => (
            <FAQItem key={index} onClick={() => handleToggle(index)}>
              <FAQQuestion expanded={expandedIndex === index}>
                {item.question}
              </FAQQuestion>
              <FAQAnswer expanded={expandedIndex === index}>
                {item.answer}
              </FAQAnswer>
            </FAQItem>
          ))}
          <FAQItem key={3} onClick={() => handleToggle(3)}>
            <FAQQuestion expanded={expandedIndex === 3}>
              {"Quais as vantagens de contar com a gente:"}
            </FAQQuestion>
            <FAQAnswer expanded={expandedIndex === 3}>
              <ul>
                <li>
                  Suporte com atendimento personalizado para nossos clientes
                </li>
                <li>
                  Praticidade através da integração eficiente entre sistemas.
                </li>
                <li>
                  Autonomia para processar e gerenciar todos os dados e
                  assinaturas de forma ágil
                </li>
                <li>
                  Um sistema adaptável às suas necessidades, assegurando mais
                  transparência e segurança no tratamento das informações.
                </li>
              </ul>
            </FAQAnswer>
          </FAQItem>
        </FAQList>
        <FAQImage
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/0ed976ad268f8c88d4f99c2d474b2bdbbef33ba4ee8f59c3868a277cd730a79e?placeholderIfAbsent=true&apiKey=bd0dc80f9a284b7b95d02b096da53a39"
          alt="FAQ illustration"
        />
      </FAQContent>
    </FAQSection>
  );
};

export default FAQ;
