import React from "react";
import styled from "styled-components";

const AboutSection = styled.section`
  text-align: center;
  padding: 80px 0;
  position: relative; /* Make this container a reference point for absolute positioning */
  overflow: hidden; /* Ensure the content does not overflow the section */
`;

const AboutBackground = styled.img`
  position: absolute; /* Position image absolutely within the parent */
  aspect-ratio: 0.97;
  object-fit: contain;
  width: 49%;
  max-width: 100%;
  margin-right: 40px;
  z-index: -1; /* Ensure the image is behind other content */
  left: 50%; /* Center horizontally */
  top: 0; /* Adjust vertical position */
  transform: translateX(-50%);

  height: auto;
  @media (max-width: 991px) {
    margin-right: 0;
    margin-bottom: 40px;
  }
`;

const AboutContent = styled.div`
  border-radius: 20px;
  padding: 48px 80px;
  max-width: 50%;
  margin: 0 auto; /* Center content */
  position: relative; /* Ensure it stays on top of the image */
  z-index: 1; /* Ensure it stays on top of the image */
  width: 90%;
  max-width: 1200px;

  @media (max-width: 991px) {
    padding: 40px 20px;
  }
`;

const AboutTitle = styled.h2`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
`;

const AboutUnderline = styled.div`
  background-color: #29a8c7;
  width: 75px;
  height: 2px;
`;

const AboutHeadline = styled.h3`
  color: #29a8c7;
  font-size: 42px;
  font-weight: 700;

  @media (max-width: 600px) {
    font-size: 32px;
  }
`;

const AboutDescription = styled.p`
  color: #3d3d3d;
  font-size: 26px;
  font-weight: 300;
  margin: 0 auto;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const About = () => (
  <AboutSection id="about">
    <AboutBackground
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/3713a09d9430d04fa98cabd8a9d708ef0ba5dd8eba849a8b6f9d62d0d32b89f4?placeholderIfAbsent=true&apiKey=bd0dc80f9a284b7b95d02b096da53a39"
      alt="Hero background"
    />
    <AboutContent>
      <TitleContainer>
        <AboutTitle>Sobre nós</AboutTitle>
        <AboutUnderline />
      </TitleContainer>

      <AboutHeadline>
        Apaixonados por inovação, estamos sempre em busca das melhores e mais
        modernas soluções para serviços financeiros.
      </AboutHeadline>
      <AboutDescription>
        A <strong>Conecta</strong> nasceu com a intenção de ser uma software
        house completa, criando e implantando sistemas ágeis e eficientes para
        automatizar processos que envolvem os segmentos bancário, financeiro e
        de veículos.
      </AboutDescription>
    </AboutContent>
  </AboutSection>
);

export default About;
