import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Carrossel3 from "../img/carrossel-3.png";

// Animação para a transição dos slides
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(1.05);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(1.05);
  }
`;

const TechnologySection = styled.section`
  background-color: #29a8c7;
  padding: 480px 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const TechnologySlide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5;
  left: 5;
  width: 90%;
  height: 90%;
  transition: opacity 0.5s ease;
  animation: ${(props) => (props.isActive ? fadeIn : fadeOut)} 0.5s forwards;
  opacity: ${(props) =>
    props.isActive ? 1 : 0}; /* Controla a opacidade para o efeito de fade */

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const TechnologyImage = styled.img`
  aspect-ratio: 0.97;
  object-fit: contain;
  width: 400px;
  max-width: 100%;
  margin-right: 40px;

  @media (max-width: 1023px) {
    margin-right: 0;
    margin-bottom: 40px;
  }
`;

const TechnologyContent = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 48px 80px;
  max-width: 633px;

  @media (max-width: 991px) {
    padding: 40px 20px;
  }
`;

const TechnologyTitle = styled.h2`
  color: #29a8c7;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: left;
`;

const TechnologyText = styled.p`
  color: #3d3d3d;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0px;
`;

// Não está sendo utilizado
// const CarouselButtons = styled.div`
//   display: flex;
//   justify-content: center;
//   margin-top: 20px;
// `;

// Não está sendo utilizado
// const CarouselButton = styled.button`
//   background-color: transparent;
//   color: transparent;
//   border: 1px solid #29a8c7;
//   padding: 10px 20px;
//   margin: 0 10px;
//   border-radius: 5px;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #29a8c7;
//     color: #fff;
//   }
// `;

const Technology = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(true);

  const slides = [
    {
      title: "Tecnologia que impulsiona resultados",
      text: "Com uma equipe altamente especializada e mais  de 10 anos de  experiência no desenvolvimento de soluções para o mercado financeiro, desenvolvemos soluções tecnológicas focadas em eficiência e inovação. Atuamos de forma ágil, criando projetos personalizados e sob medida, sempre com foco nos resultados e crescimento nos negócios dos nossos clientes.",
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/260dbb3f8a7f6ffa8b883f65a10c5bdc60e6bc9f72d281696196f519fbd5d6e9?placeholderIfAbsent=true&apiKey=bd0dc80f9a284b7b95d02b096da53a39",
    },
    {
      title: "Atuação",
      text: "A Conecta está presente em todo o território nacional, com a missão de conectar sistemas e simplificar processos. Nossa atuação é impulsionada pela criação de soluções inovadoras e eficientes, projetadas para automatizar operações nos segmentos bancário, financeiro e de veículos.",
      image: Carrossel3,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(false);
      setTimeout(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        setIsAnimating(true);
      }, 500); // Espera pela duração da animação para mudar o slide
    }, 5000); // Tempo de transição automática

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <TechnologySection>
      {slides.map((slide, index) => (
        <TechnologySlide
          key={index}
          isActive={currentSlide === index && isAnimating}
        >
          <TechnologyImage src={slide.image} alt="Technology illustration" />
          <TechnologyContent>
            <TechnologyTitle>{slide.title}</TechnologyTitle>
            <TechnologyText>{slide.text}</TechnologyText>
          </TechnologyContent>
        </TechnologySlide>
      ))}

      {/* Não está sendo utilizado */}
      {/* <CarouselButtons>
        <CarouselButton
          onClick={() =>
            setCurrentSlide((currentSlide - 1 + slides.length) % slides.length)
          }
        >
          Anterior
        </CarouselButton>
        <CarouselButton
          onClick={() => setCurrentSlide((currentSlide + 1) % slides.length)}
        >
          Próximo
        </CarouselButton>
      </CarouselButtons> */}
    </TechnologySection>
  );
};

export default Technology;
