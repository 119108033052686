/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const ShowcaseSection = styled.section`
  position: relative;
  min-height: 900px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    padding: 100px 20px;
    min-height: auto;
  }
`;

const ShowcaseBackground = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const ShowcaseContent = styled.div`
  position: relative;
  z-index: 1;
  max-width: 1250px;
  width: 100%;
`;

const ProductLogo = styled.img`
  aspect-ratio: 2.94;
  object-fit: contain;
  width: 303px;
  max-width: 100%;
  margin-bottom: 30px;
`;

const ProductDescription = styled.p`
  color: #3d3d3d;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.75;
  max-width: 722px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const ProductShowcase = () => (
  <ShowcaseSection>
    <ShowcaseBackground
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/e407c186f7cb6384c2a399d7da48e0a3774f01aeafaf396fb0f2a473ae126822?placeholderIfAbsent=true&apiKey=bd0dc80f9a284b7b95d02b096da53a39"
      alt="Product showcase background"
    />
    <ShowcaseContent>
      <ProductLogo
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc5c409a356304f3015c98c99b8e86d368b09fccc42f001d9a60fdd55cb5098e?placeholderIfAbsent=true&apiKey=bd0dc80f9a284b7b95d02b096da53a39"
        alt="TEV logo"
      />
      <ProductDescription>
        A plataforma <strong>TEV</strong> facilita a{" "}
        <strong>
          Autorização para Transferência de Propriedade do veículo
        </strong>
        , integrando agentes financeiros aos processos realizados pelo SENATRAN.
        <br />
        <br />
        Ela permite <strong>processar e gerenciar</strong> as assinaturas
        eletrônicas do <strong>ATPV-e</strong>, enviando{" "}
        <strong>operações em lote ou individualmente</strong>, com{" "}
        <strong>retorno em tempo real</strong> e{" "}
        <strong>relatórios personalizados</strong>, além de transferir o ônus em
        contratos de leasing.
      </ProductDescription>
    </ShowcaseContent>
  </ShowcaseSection>
);

export default ProductShowcase;
