/**
* This code was generated by Builder.io.
*/
import React from "react";
import styled from "styled-components";

const LogoImage = styled.img`
aspect-ratio: 3.48;
object-fit: contain;
width: 164px;
max-width: 100%;
`;

const FooterAddress = styled.p`
font-size: 14px;
margin-bottom: 10px;
z-index: 9999;
margin-top: 10px;
`;

const Logo = () => (
  <div>
    <LogoImage
    src="https://cdn.builder.io/api/v1/image/assets/TEMP/df9e1d337c09bc7744b21d3fdf6aeb7f95ba2d98b9bdc0603681f22c71ab1f24?placeholderIfAbsent=true&apiKey=bd0dc80f9a284b7b95d02b096da53a39"
    alt="Conecta Logo"
    />
    <FooterAddress>
    Rua Cubatão, 86 - Vila Mariana
    <br />
    São Paulo - SP, CEP 04013-000
    </FooterAddress>
  </div>
  );
  
  export default Logo;
  