/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import ConnectionGif from "../../img/gif-home.gif";

function HeroSection() {
  return (
    <Section>
      <ConnectionGifStyled src={ConnectionGif} />
      <Description>
        Nos comprometemos em criar e implantar sistemas inovadores e eficientes
        que automatizam processos dos segmentos financeiro e de veículos.
      </Description>
      <ContactButton href="https://wa.me/5511912229926">
        Fale conosco
      </ContactButton>
    </Section>
  );
}

const Section = styled.section`
  display: flex;
  width: 508px;
  max-width: 100%;
  flex-direction: column;
  margin: 80px 0px;
`;

const Description = styled.p`
  font-size: 22px;

  @media (max-width: 991px) {
    max-width: 100%;
  }

  @media (min-width: 1024px) {
    margin-top: -100px;
  }
`;

const ConnectionGifStyled = styled.img`
  object-fit: cover;
  width: 600px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ContactButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: start;
  border-radius: 30px;
  background-color: #29a8c7;
  margin-top: 30px;
  min-height: 32px;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  color: inherit;
  cursor: pointer;
  z-index: 9999;
  text-decoration: none;
  text-align: center;
`;

export default HeroSection;
