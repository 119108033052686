import React from "react";
import styled from "styled-components";
import SolutionImage from "../img/img-solucao.png";

const SolutionsSection = styled.section`
  background-color: rgba(61, 61, 61, 0.102);
  padding: 132px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1023px) {
    padding: 100px 20px;
  }
`;

const SolutionsTitle = styled.h2`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
`;

const SolutionsUnderline = styled.div`
  background-color: #29a8c7;
  width: 75px;
  height: 2px;
  margin-bottom: 92px;

  @media (max-width: 1023px) {
    margin-bottom: 40px;
  }
`;

const SolutionsContent = styled.div`
  display: flex;
  gap: 90px;
  max-width: 1250px;
  width: 100%;

  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 64px;
  }
`;

const SolutionsDescription = styled.p`
  color: #3d3d3d;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.4;

  @media (max-width: 1023px) {
    font-size: 20px;
  }
`;

const SolutionsImage = styled.img`
  aspect-ratio: 0.97;
  object-fit: contain;
  width: 334px;
  max-width: 100%;
  margin-right: 40px;
  align-self: center;

  @media (max-width: 1023px) {
    margin-right: 0;
  }
`;

const Solutions = () => (
  <SolutionsSection id="solutions">
    <SolutionsTitle>Solução</SolutionsTitle>
    <SolutionsUnderline />
    <SolutionsContent>
      <SolutionsImage src={SolutionImage} alt="Technology illustration" />
      <SolutionsDescription>
        Nossas soluções são desenvolvidas para proporcionar máxima{" "}
        <strong>agilidade e eficiência</strong>, automatizando processos manuais
        e impulsionando resultados superiores.
        <br />
        <br />
        Com a integração da <strong>Conecta</strong> ao <strong>Registro Civil</strong>, eliminamos burocracias, como{" "}
        deslocamentos ao <strong>DETRAN</strong> e cartórios, unificando processos e proporcionando uma{""}
        experiência mais ágil.
      </SolutionsDescription>
    </SolutionsContent>
  </SolutionsSection>
);

export default Solutions;
