import React, { useState } from "react";
import styled from "styled-components";

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);

  const navItems = [
    { label: "Home", href: "#" },
    { label: "Sobre nós", href: "#about" },
    { label: "Segurança", href: "#security" },
    { label: "Solução", href: "#solutions" },
    { label: "Compliance", href: "#compliance" },
    { label: "FAQ", href: "#faq" },
    { label: "Fale conosco", href: "https://wa.me/5511912229926" },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Header>
        <HamburgerButton onClick={toggleMenu}>
          <span />
          <span />
          <span />
        </HamburgerButton>

        <Nav isOpen={isOpen}>
          {navItems.map((item, index) => (
            <NavItem key={index} href={item.href} onClick={toggleMenu}>
              {item.label}
            </NavItem>
          ))}
          <MooveButton 
            onClick={() => window.open('https://conectamoove.net.br', 'blank')} 
          >
            Moove
          </MooveButton>
        </Nav>
      </Header>
    </>
  );
}

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 8px;
  background-color: transparent;
  position: relative;
  z-index: 100;
`;

const HamburgerButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 101;

  span {
    width: 100%;
    height: 3px;
    background-color: #fff;
    border-radius: 3px;
    transition: all 0.3s ease;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 39px;
  justify-content: flex-start;
  align-items: center;
  margin: auto 0;

  @media (max-width: 1023px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: -200%;
    width: 100px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }
`;

const NavItem = styled.a`
  text-decoration: none;
  color: inherit;

  @media (max-width: 1023px) {
    padding: 10px 0;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    color: #000;
  }
`;

const tevButton = styled.button`
  border-radius: 16px;
  background-color: #fdb913;
  min-height: 32px;
  color: #000;
  font-weight: 700;
  width: 103px;
  border: none;
  cursor: pointer;

  @media (max-width: 991px) {
    white-space: initial;
    padding: 7px 20px;
  }
`;

const MooveButton = styled.button`
  border-radius: 16px;
  background-color: #29A8C7;
  min-height: 32px;
  color: #FFFFFF;
  font-weight: 700;
  width: 103px;
  border: none;
  cursor: pointer;

  @media (max-width: 991px) {
    white-space: initial;
    padding: 7px 20px;
  }
`;

export default Navigation;
