import React from "react";
import styled from "styled-components";
import Header from "./Header";
import HeroSection from "./HeroSection";
import VideoBack from './../../img/video_background.mp4'

function LandingPage() {
  return (
    <Main>
      <BackgroundVideo autoPlay muted loop playsInline>
        <source
          src={VideoBack}
          type="video/mp4"
        />
        Seu navegador não suporta a tag de vídeo.
      </BackgroundVideo>
      <ContentWrapper>
        <Header />
        <HeroSection />
      </ContentWrapper>
    </Main>
  );
}

const Main = styled.main`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  font-family: Inter, sans-serif;
  color: #fff;
  font-weight: 400;
  padding: 42px 80px 0px;

  @media (max-width: 991px) {
    padding: 0 20px 0px;
  }
`;

const BackgroundVideo = styled.video`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  width: 1200px;
  max-width: 100%;
  flex-direction: column;
`;

export default LandingPage;
