import React from "react";
import styled from "styled-components";

const SecuritySection = styled.section`
  background-color: #fff;
  padding: 132px 80px 206px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 991px) {
    padding: 100px 20px;
  }
`;

const SecurityTitle = styled.h2`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
`;

const SecurityUnderline = styled.div`
  background-color: #29a8c7;
  width: 75px;
  height: 2px;
  margin-bottom: 72px;

  @media (max-width: 991px) {
    margin-bottom: 40px;
  }
`;

const SecurityContent = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  gap: 5px;
  justify-content: center;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SecurityImage = styled.img`
  width: 188px;
  object-fit: contain;

  @media (max-width: 600px) {
    display: none;
  }
`;

const SecurityInfo = styled.div`
  max-width: 356px;
  background-color: #e9f6f9;
  padding: 0px 32px;
  border-radius: 0px 10px 10px 0px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 29rem;
`;

const SecurityInfoWhite = styled.div`
  max-width: 600px;
  background-color: #fff; /* Fundo branco para a segunda div */
  padding: 0px 20px;
  flex-grow: 1; /* Faz com que essa div tenha o mesmo tamanho vertical da outra */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Alinha o conteúdo ao topo */
`;

const SecuritySubtitle = styled.h3`
  margin-top: 0;
  color: #29a8c7;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
`;

const SecurityText = styled.p`
  color: #3d3d3d;
  font-size: 16px;
  line-height: 1.5;
  text-align: left; /* Garante que o texto fique alinhado à esquerda */
`;

const SecurityFeatures = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const SecurityFeature = styled.li`
  background-color: rgba(61, 61, 61, 0.102);
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
`;

const Security = () => (
  <SecuritySection id="security">
    <SecurityTitle>Segurança</SecurityTitle>
    <SecurityUnderline />
    <SecurityContent>
      <div style={{ display: "flex", alignItems: "start" }}>
        <SecurityImage
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd33271a59d9da9efb5eb58decd0b36727a0e0d5776cd6664793b0ec06e5ae98?placeholderIfAbsent=true&apiKey=bd0dc80f9a284b7b95d02b096da53a39"
          alt="Security illustration"
        />
        <SecurityInfo>
          <SecuritySubtitle>Certificações e segurança</SecuritySubtitle>
          <SecurityText>
            Oferecer soluções para os segmentos bancário e financeiro requer
            muita segurança e responsabilidade. Por este motivo, a Conecta, mais
            uma vez, utiliza a tecnologia a seu favor, fazendo uso do que há de
            mais moderno no que diz respeito a proteção de dados.
          </SecurityText>
        </SecurityInfo>
      </div>

      <SecurityInfoWhite>
        <SecurityFeatures>
          <SecuritySubtitle>
            Aliado a isto, fazem parte do seu esquema de segurança
          </SecuritySubtitle>
          <SecurityFeature>
            Data center de primeira linha, que fornece alta disponibilidade
            todos os dias do ano
          </SecurityFeature>
          <SecurityFeature style={{ backgroundColor: "#E9F6F9" }}>
            Redundância N+1 em todos os sistemas. O que garante a sustentação
            com 100% de eficácia.
          </SecurityFeature>

          <SecurityFeature>
            Entrada redundante, subterrânea de fibras ópticas
          </SecurityFeature>
          <SecurityFeature style={{ backgroundColor: "#E9F6F9" }}>
            Link de internet redundante entre 4 operadoras distintas
          </SecurityFeature>
        </SecurityFeatures>
      </SecurityInfoWhite>
    </SecurityContent>
  </SecuritySection>
);

export default Security;
