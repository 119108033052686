/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import Navigation from "./Navigation";

function Header() {
  return (
    <HeaderWrapper>
      <Logo
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/df9e1d337c09bc7744b21d3fdf6aeb7f95ba2d98b9bdc0603681f22c71ab1f24?placeholderIfAbsent=true&apiKey=bd0dc80f9a284b7b95d02b096da53a39"
        alt="Company Logo"
      />
      <Navigation />
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  flex-wrap: wrap;
  z-index: 99999;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Logo = styled.img`
  aspect-ratio: 3.48;
  object-fit: contain;
  object-position: center;
  width: 164px;
  align-self: stretch;
  margin: auto 0;
`;

export default Header;
