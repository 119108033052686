/**
 * This code was generated by Builder.io.
 */
import React from "react";
import About from "./About";
import Technology from "./Technology";
import Security from "./Security";
import Solutions from "./Solutions";
import ProductShowcase from "./ProductShowcase";
import Commitment from "./Commitment";
import FAQ from "./FAQ";
import Footer from "./Footer";
import LandingPage from "./Header/LandingPage";

const MainPage = () => (
  <>
    <LandingPage />
    <main>
      <About />
      <Technology />
      <Security />
      <Solutions />
      <ProductShowcase />
      <Commitment />
      <FAQ />
    </main>
    <Footer />
  </>
);

export default MainPage;
