/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";

const ShowcaseSection = styled.section`
  position: relative;
  min-height: 900px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    padding: 100px 20px;
    min-height: auto;
  }
`;

const ShowcaseBackground = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const ShowcaseContent = styled.div`
  position: relative;
  z-index: 1;
  max-width: 1250px;
  width: 100%;
`;

const ProductLogo = styled.img`
  aspect-ratio: 2.94;
  max-width: 100%;
  margin-bottom: 30px;
`;

const ProductDescription = styled.p`
  color: #3d3d3d;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.75;
  max-width: 722px;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const ProductShowcase = () => (
  <ShowcaseSection>
    <ShowcaseBackground
      src="/arrow_blue.png"
      alt="Product showcase background"
    />
    <ShowcaseContent>
      <ProductLogo
        src="/logo_moove_resize.png"
        alt="TEV logo"
      />
      <ProductDescription>
        Apresentamos uma solução inovadora e segura para a emissão e assinatura da <strong>ATPV-e</strong>,{""}
        obrigatória para veículos desde 2021.
        <br />
        <br />
        Nossa plataforma <strong>Moove</strong> integra empresas ao <strong>Registro Civil</strong>, facilitando o{" "}
        gerenciamento eficiente das assinaturas eletrônicas da <strong>ATPV-e</strong>. Com envio de operações{" "}
        em lote ou individualmente, retorno em tempo real e relatórios personalizados,{" "}
        proporcionamos mais controle, agilidade e segurança em todo o processo.
      </ProductDescription>
    </ShowcaseContent>
  </ShowcaseSection>
);

export default ProductShowcase;
